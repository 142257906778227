<template>
  <div class="home">
    <div class="back" @click="back"></div>
    <div class="star-bg">
      <div class="star"></div>
      <div class="star-label" v-if="showResult">录取结果</div>
    </div>
    <div class="score-w">
      <div class="student-info">
        <strong>{{ scoreData.name }}</strong>
        <span v-if="showResult">{{ scoreData.admitResult }}</span>
      </div>

      <template v-for="(item, index) in scoreData.titleResult">
        <div class="list" :key="index">
          <div class="table-l">
            <div>
              {{ scoreData.titleResult[index] }}
            </div>
          </div>
          <div class="table-r">
            <div>
              {{ scoreData.examResult[index] }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="button">
      <div class="btn" @click="back">退出查询</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',

  data() {
    return {
      scoreData: {}
    }
  },

  created() {
    this.checkSessionFormParam()
  },

  computed: {
    showResult() {
      let scoreData = this.scoreData
      if (scoreData.admitResult && scoreData.admitResult.length) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    checkSessionFormParam() {
      let formParam = this.$util.getSession('formParam')
      if (!formParam) {
        this.$router.push('/login')
      } else {
        this.getScoreData(formParam)
      }
    },

    back() {
      this.$router.push('/login')
    },

    getScoreData(param) {
      this.$ajax.get('/getScore', param).then(res => {
        if (res.code === 200) {
          this.scoreData = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  background: url('~@/assets/home/bg_t.png');

  .back {
    z-index: 100;
    position: fixed;
    top: -0.1rem;
    left: -0.1rem;
    width: 0.6rem;
    height: 0.6rem;
    background: url('~@/assets/home/arrow_left.png');
  }
  .star-bg {
    padding-top: 0.32rem;
    position: relative;
    .star {
      background: url('~@/assets/home/star.png');
      width: 2.95rem;
      height: 1.46rem;
      background-size: contain;
      margin: 0 auto;
    }

    .star-label {
      position: absolute;
      left: 50%;
      bottom: 0.22rem;
      transform: translateX(-50%);
      font-size: 0.2rem;
      color: #ffffff;
      letter-spacing: 0.08rem;
      text-align: center;
      font-weight: bold;
    }
  }

  .score-w {
    background: #ffffff;
    box-shadow: 0 0.06rem 0.04rem 0 #a7c6d5;
    border-radius: 0.05rem;
    width: 3.51rem;
    height: 2.85rem;
    overflow: auto;
    margin: 0 auto;
    margin-top: -0.1rem;

    .student-info {
      background: #ccf1fc;
      border-radius: 0.25rem;
      width: 2.8rem;
      height: 0.46rem;
      display: flex;
      align-items: center;
      font-size: 0.2rem;
      color: #0e858e;
      justify-content: space-around;
      margin: 0.2rem auto 0.2rem auto;
    }

    .list {
      display: flex;
      padding: 0 0.15rem;

      .table-l {
        width: 1.5rem;
        line-height: 0.4rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.16rem;
        color: #ffffff;

        div {
          background: #11a8b4;
          padding-left: 0.12rem;
          border-bottom: 1px solid #fff;
        }

        &:first-child {
          border-radius: 4px 0 0 0;
        }
        &:last-child {
          border-radius: 0 0 0 4px;
        }
      }

      .table-r {
        flex: 1;
        line-height: 0.4rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.16rem;
        color: #4a4a4a;
        text-align: right;

        div {
          background: #ddf7ff;
          padding-right: 0.1rem;
          border-bottom: 1px solid #fff;
          height: 0.4rem;

          &:first-child {
            border-radius: 4px 4px 0 0;
          }
        }
        &:last-child {
          border-radius: 0 0 4px 0;
        }
      }
    }
  }

  .button {
    z-index: 10;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 1.8rem;
    background: url('~@/assets/home/bg_b.png');
    background-size: contain;

    .btn {
      position: absolute;
      background: #ffffff;
      box-shadow: 0 0.02rem 0.06rem 0 rgba(0, 0, 0, 0.35);
      border-radius: 0.25rem;
      width: 2rem;
      line-height: 0.46rem;
      text-align: center;
      font-size: 0.16rem;
      color: #787878;
      bottom: 0.2rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
